const data = [
  {
    UnitID: {
      value: 1001,
      UniqueID: 4607,
    },
    PersonID: {
      value: "t0002319",
      Type: "Other",
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 647,
      MaxSquareFeet: 647,
      MarketRent: 2795,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Rented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1001",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1001,
      UniqueID: 4607,
    },
    PersonID: {
      value: "t0002322",
      Type: "Applicant",
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 647,
      MaxSquareFeet: 647,
      MarketRent: 2795,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Rented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1001",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1002,
      UniqueID: 4608,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 80,
        },
      ],
      UnitType: "fr-af-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 783,
      MaxSquareFeet: 783,
      MarketRent: 1907,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - affordable",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1002",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1003,
      UniqueID: 4614,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 79,
        },
      ],
      UnitType: "fr-af-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 494,
      MaxSquareFeet: 494,
      MarketRent: 2275,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - affordable",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1003",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1004,
      UniqueID: 4609,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 717,
      MaxSquareFeet: 717,
      MarketRent: 3095,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1004",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1005,
      UniqueID: 4610,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 82,
        },
      ],
      UnitType: "fr-af-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 619,
      MaxSquareFeet: 619,
      MarketRent: 1035,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - affordable",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1005",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1006,
      UniqueID: 4611,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 86,
        },
      ],
      UnitType: "fr-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 619,
      MaxSquareFeet: 619,
      MarketRent: 2595,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1006",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1007,
      UniqueID: 4612,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 721,
      MaxSquareFeet: 721,
      MarketRent: 3195,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1007",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1008,
      UniqueID: 4613,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 502,
      MaxSquareFeet: 502,
      MarketRent: 2350,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1008",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1009,
      UniqueID: 4605,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 801,
      MaxSquareFeet: 801,
      MarketRent: 3350,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1009",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1010,
      UniqueID: 4606,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 633,
      MaxSquareFeet: 633,
      MarketRent: 2850,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1010",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1011,
      UniqueID: 4616,
    },
    PersonID: {
      value: "t0002351",
      Type: "Applicant",
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 633,
      MaxSquareFeet: 633,
      MarketRent: 2850,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Rented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1011",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1012,
      UniqueID: 4615,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 801,
      MaxSquareFeet: 801,
      MarketRent: 3350,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1012",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1013,
      UniqueID: 4623,
    },
    PersonID: {
      value: "t0002419",
      Type: "Applicant",
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 502,
      MaxSquareFeet: 502,
      MarketRent: 2350,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Rented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1013",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1014,
      UniqueID: 4622,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 721,
      MaxSquareFeet: 721,
      MarketRent: 3095,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1014",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1015,
      UniqueID: 4621,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 86,
        },
      ],
      UnitType: "fr-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 619,
      MaxSquareFeet: 619,
      MarketRent: 2595,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1015",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1016,
      UniqueID: 4620,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 82,
        },
      ],
      UnitType: "fr-af-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 619,
      MaxSquareFeet: 619,
      MarketRent: 1035,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - affordable",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1016",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1017,
      UniqueID: 4619,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 717,
      MaxSquareFeet: 717,
      MarketRent: 3095,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1017",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1018,
      UniqueID: 4624,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 494,
      MaxSquareFeet: 494,
      MarketRent: 2295,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1018",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1019,
      UniqueID: 4618,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 80,
        },
      ],
      UnitType: "fr-af-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 783,
      MaxSquareFeet: 783,
      MarketRent: 1907,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - affordable",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1019",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1020,
      UniqueID: 4617,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 647,
      MaxSquareFeet: 647,
      MarketRent: 2795,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1020",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1101,
      UniqueID: 4627,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 647,
      MaxSquareFeet: 647,
      MarketRent: 2795,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1101",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1102,
      UniqueID: 4628,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 783,
      MaxSquareFeet: 783,
      MarketRent: 3350,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1102",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1103,
      UniqueID: 4634,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 494,
      MaxSquareFeet: 494,
      MarketRent: 2275,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1103",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1104,
      UniqueID: 4629,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 717,
      MaxSquareFeet: 717,
      MarketRent: 3095,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1104",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1105,
      UniqueID: 4630,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 86,
        },
      ],
      UnitType: "fr-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 619,
      MaxSquareFeet: 619,
      MarketRent: 2650,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1105",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1106,
      UniqueID: 4631,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 82,
        },
      ],
      UnitType: "fr-af-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 619,
      MaxSquareFeet: 619,
      MarketRent: 1035,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - affordable",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1106",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1107,
      UniqueID: 4632,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 721,
      MaxSquareFeet: 721,
      MarketRent: 2995,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1107",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1108,
      UniqueID: 4633,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 79,
        },
      ],
      UnitType: "fr-af-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 502,
      MaxSquareFeet: 502,
      MarketRent: 1405,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - affordable",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1108",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1109,
      UniqueID: 4625,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 80,
        },
      ],
      UnitType: "fr-af-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 801,
      MaxSquareFeet: 801,
      MarketRent: 1907,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - affordable",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1109",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1110,
      UniqueID: 4626,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 633,
      MaxSquareFeet: 633,
      MarketRent: 2850,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1110",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1111,
      UniqueID: 4636,
    },
    PersonID: {
      value: "t0002439",
      Type: "Applicant",
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 633,
      MaxSquareFeet: 633,
      MarketRent: 2850,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Rented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1111",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1112,
      UniqueID: 4635,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 80,
        },
      ],
      UnitType: "fr-af-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 801,
      MaxSquareFeet: 801,
      MarketRent: 1907,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - affordable",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1112",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1113,
      UniqueID: 4643,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 502,
      MaxSquareFeet: 502,
      MarketRent: 2550,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1113",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1114,
      UniqueID: 4642,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 721,
      MaxSquareFeet: 721,
      MarketRent: 2995,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1114",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1115,
      UniqueID: 4641,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 82,
        },
      ],
      UnitType: "fr-af-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 619,
      MaxSquareFeet: 619,
      MarketRent: 1035,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - affordable",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1115",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1116,
      UniqueID: 4640,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 86,
        },
      ],
      UnitType: "fr-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 619,
      MaxSquareFeet: 619,
      MarketRent: 2650,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1116",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1117,
      UniqueID: 4639,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 717,
      MaxSquareFeet: 717,
      MarketRent: 3095,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1117",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1118,
      UniqueID: 4644,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 79,
        },
      ],
      UnitType: "fr-af-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 494,
      MaxSquareFeet: 494,
      MarketRent: 2295,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - affordable",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1118",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1119,
      UniqueID: 4638,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 783,
      MaxSquareFeet: 783,
      MarketRent: 3350,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1119",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1120,
      UniqueID: 4637,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 647,
      MaxSquareFeet: 647,
      MarketRent: 2795,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1120",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1201,
      UniqueID: 4647,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 647,
      MaxSquareFeet: 647,
      MarketRent: 2795,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1201",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1202,
      UniqueID: 4648,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 80,
        },
      ],
      UnitType: "fr-af-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 783,
      MaxSquareFeet: 783,
      MarketRent: 1907,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - affordable",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1202",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1203,
      UniqueID: 4654,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 494,
      MaxSquareFeet: 494,
      MarketRent: 2275,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1203",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1204,
      UniqueID: 4649,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 717,
      MaxSquareFeet: 717,
      MarketRent: 3095,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1204",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1205,
      UniqueID: 4650,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 82,
        },
      ],
      UnitType: "fr-af-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 619,
      MaxSquareFeet: 619,
      MarketRent: 1035,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - affordable",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1205",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1206,
      UniqueID: 4651,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 86,
        },
      ],
      UnitType: "fr-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 619,
      MaxSquareFeet: 619,
      MarketRent: 2650,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1206",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1207,
      UniqueID: 4652,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 721,
      MaxSquareFeet: 721,
      MarketRent: 3050,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1207",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1208,
      UniqueID: 4653,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 79,
        },
      ],
      UnitType: "fr-af-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 502,
      MaxSquareFeet: 502,
      MarketRent: 1405,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - affordable",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1208",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1209,
      UniqueID: 4645,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 801,
      MaxSquareFeet: 801,
      MarketRent: 3395,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1209",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1210,
      UniqueID: 4646,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 633,
      MaxSquareFeet: 633,
      MarketRent: 2895,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1210",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1211,
      UniqueID: 4656,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 633,
      MaxSquareFeet: 633,
      MarketRent: 2895,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1211",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1212,
      UniqueID: 4655,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 801,
      MaxSquareFeet: 801,
      MarketRent: 3395,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1212",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1213,
      UniqueID: 4663,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 79,
        },
      ],
      UnitType: "fr-af-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 502,
      MaxSquareFeet: 502,
      MarketRent: 1405,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - affordable",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1213",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1214,
      UniqueID: 4662,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 721,
      MaxSquareFeet: 721,
      MarketRent: 3050,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1214",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1215,
      UniqueID: 4661,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 86,
        },
      ],
      UnitType: "fr-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 619,
      MaxSquareFeet: 619,
      MarketRent: 2650,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1215",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1216,
      UniqueID: 4660,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 82,
        },
      ],
      UnitType: "fr-af-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 619,
      MaxSquareFeet: 619,
      MarketRent: 1035,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - affordable",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1216",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1217,
      UniqueID: 4659,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 717,
      MaxSquareFeet: 717,
      MarketRent: 3095,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1217",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1218,
      UniqueID: 4664,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 494,
      MaxSquareFeet: 494,
      MarketRent: 2295,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1218",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1219,
      UniqueID: 4658,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 80,
        },
      ],
      UnitType: "fr-af-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 783,
      MaxSquareFeet: 783,
      MarketRent: 3350,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - affordable",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1219",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1220,
      UniqueID: 4657,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 647,
      MaxSquareFeet: 647,
      MarketRent: 2795,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1220",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1301,
      UniqueID: 4667,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 647,
      MaxSquareFeet: 647,
      MarketRent: 2850,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1301",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1302,
      UniqueID: 4668,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 80,
        },
      ],
      UnitType: "fr-af-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 783,
      MaxSquareFeet: 783,
      MarketRent: 1907,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - affordable",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1302",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1303,
      UniqueID: 4674,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 494,
      MaxSquareFeet: 494,
      MarketRent: 2325,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1303",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1304,
      UniqueID: 4669,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 717,
      MaxSquareFeet: 717,
      MarketRent: 3150,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1304",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1305,
      UniqueID: 4670,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 86,
        },
      ],
      UnitType: "fr-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 619,
      MaxSquareFeet: 619,
      MarketRent: 2650,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1305",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1306,
      UniqueID: 4671,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 82,
        },
      ],
      UnitType: "fr-af-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 619,
      MaxSquareFeet: 619,
      MarketRent: 1035,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - affordable",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1306",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1307,
      UniqueID: 4672,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 721,
      MaxSquareFeet: 721,
      MarketRent: 3150,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1307",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1308,
      UniqueID: 4673,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 502,
      MaxSquareFeet: 502,
      MarketRent: 2550,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1308",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1309,
      UniqueID: 4665,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 80,
        },
      ],
      UnitType: "fr-af-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 801,
      MaxSquareFeet: 801,
      MarketRent: 1907,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - affordable",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1309",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1310,
      UniqueID: 4666,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 633,
      MaxSquareFeet: 633,
      MarketRent: 2850,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1310",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1311,
      UniqueID: 4676,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 633,
      MaxSquareFeet: 633,
      MarketRent: 2850,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1311",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1312,
      UniqueID: 4675,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 80,
        },
      ],
      UnitType: "fr-af-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 801,
      MaxSquareFeet: 801,
      MarketRent: 1907,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - affordable",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1312",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1313,
      UniqueID: 4683,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 502,
      MaxSquareFeet: 502,
      MarketRent: 2550,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1313",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1314,
      UniqueID: 4682,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 721,
      MaxSquareFeet: 721,
      MarketRent: 3150,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1314",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1315,
      UniqueID: 4681,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 82,
        },
      ],
      UnitType: "fr-af-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 619,
      MaxSquareFeet: 619,
      MarketRent: 1035,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - affordable",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1315",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1316,
      UniqueID: 4680,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 86,
        },
      ],
      UnitType: "fr-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 619,
      MaxSquareFeet: 619,
      MarketRent: 2650,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1316",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1317,
      UniqueID: 4679,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 717,
      MaxSquareFeet: 717,
      MarketRent: 3150,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1317",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1318,
      UniqueID: 4684,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 494,
      MaxSquareFeet: 494,
      MarketRent: 2350,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1318",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1319,
      UniqueID: 4678,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 80,
        },
      ],
      UnitType: "fr-af-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 783,
      MaxSquareFeet: 783,
      MarketRent: 1907,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - affordable",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1319",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1320,
      UniqueID: 4677,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 647,
      MaxSquareFeet: 647,
      MarketRent: 2850,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1320",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1401,
      UniqueID: 4687,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 80,
        },
      ],
      UnitType: "fr-af-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 945,
      MaxSquareFeet: 945,
      MarketRent: 1907,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - affordable",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1401",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1402,
      UniqueID: 4688,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 926,
      MaxSquareFeet: 926,
      MarketRent: 3695,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1402",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1403,
      UniqueID: 4689,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 929,
      MaxSquareFeet: 929,
      MarketRent: 4095,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1403",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1404,
      UniqueID: 4690,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 79,
        },
      ],
      UnitType: "fr-af-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 659,
      MaxSquareFeet: 659,
      MarketRent: 1405,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - affordable",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1404",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1405,
      UniqueID: 4691,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 86,
        },
      ],
      UnitType: "fr-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 601,
      MaxSquareFeet: 601,
      MarketRent: 2650,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1405",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1406,
      UniqueID: 4692,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 647,
      MaxSquareFeet: 647,
      MarketRent: 2795,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1406",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1407,
      UniqueID: 4693,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 443,
      MaxSquareFeet: 443,
      MarketRent: 2315.4,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1407",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1408,
      UniqueID: 4685,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 870,
      MaxSquareFeet: 870,
      MarketRent: 3794.4,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1408",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1409,
      UniqueID: 4686,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 82,
        },
      ],
      UnitType: "fr-af-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 511,
      MaxSquareFeet: 511,
      MarketRent: 1035,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - affordable",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1409",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1410,
      UniqueID: 4695,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 82,
        },
      ],
      UnitType: "fr-af-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 511,
      MaxSquareFeet: 511,
      MarketRent: 1035,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - affordable",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1410",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1411,
      UniqueID: 4694,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 870,
      MaxSquareFeet: 870,
      MarketRent: 3700,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1411",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1412,
      UniqueID: 4702,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 443,
      MaxSquareFeet: 443,
      MarketRent: 2315.4,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1412",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1413,
      UniqueID: 4701,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 647,
      MaxSquareFeet: 647,
      MarketRent: 2795,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1413",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1414,
      UniqueID: 4700,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 86,
        },
      ],
      UnitType: "fr-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 601,
      MaxSquareFeet: 601,
      MarketRent: 2650,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1414",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1415,
      UniqueID: 4699,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 79,
        },
      ],
      UnitType: "fr-af-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 659,
      MaxSquareFeet: 659,
      MarketRent: 1405,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - affordable",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1415",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1416,
      UniqueID: 4698,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 929,
      MaxSquareFeet: 929,
      MarketRent: 4095,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1416",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1417,
      UniqueID: 4697,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 926,
      MaxSquareFeet: 926,
      MarketRent: 3695,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1417",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1418,
      UniqueID: 4696,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 80,
        },
      ],
      UnitType: "fr-af-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 945,
      MaxSquareFeet: 945,
      MarketRent: 1907,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - affordable",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1418",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1501,
      UniqueID: 4705,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 80,
        },
      ],
      UnitType: "fr-af-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 945,
      MaxSquareFeet: 945,
      MarketRent: 1907,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - affordable",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1501",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1502,
      UniqueID: 4706,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 926,
      MaxSquareFeet: 926,
      MarketRent: 3750,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1502",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1503,
      UniqueID: 4707,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 929,
      MaxSquareFeet: 929,
      MarketRent: 4095,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1503",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1504,
      UniqueID: 4708,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 659,
      MaxSquareFeet: 659,
      MarketRent: 2695,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1504",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1505,
      UniqueID: 4709,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 82,
        },
      ],
      UnitType: "fr-af-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 601,
      MaxSquareFeet: 601,
      MarketRent: 1035,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - affordable",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1505",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1506,
      UniqueID: 4710,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 647,
      MaxSquareFeet: 647,
      MarketRent: 3468,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1506",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1507,
      UniqueID: 4711,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 79,
        },
      ],
      UnitType: "fr-af-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 443,
      MaxSquareFeet: 443,
      MarketRent: 1405,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - affordable",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1507",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1508,
      UniqueID: 4703,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 870,
      MaxSquareFeet: 870,
      MarketRent: 3845.4,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1508",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1509,
      UniqueID: 4704,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 86,
        },
      ],
      UnitType: "fr-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 511,
      MaxSquareFeet: 511,
      MarketRent: 2805,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1509",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1510,
      UniqueID: 4713,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 86,
        },
      ],
      UnitType: "fr-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 511,
      MaxSquareFeet: 511,
      MarketRent: 2805,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1510",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1511,
      UniqueID: 4712,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 870,
      MaxSquareFeet: 870,
      MarketRent: 3700,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1511",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1512,
      UniqueID: 4720,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 443,
      MaxSquareFeet: 443,
      MarketRent: 2315.4,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1512",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1513,
      UniqueID: 4719,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 647,
      MaxSquareFeet: 647,
      MarketRent: 2850,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1513",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1514,
      UniqueID: 4718,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 82,
        },
      ],
      UnitType: "fr-af-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 601,
      MaxSquareFeet: 601,
      MarketRent: 1035,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - affordable",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1514",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1515,
      UniqueID: 4717,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 79,
        },
      ],
      UnitType: "fr-af-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 659,
      MaxSquareFeet: 659,
      MarketRent: 1405,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - affordable",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1515",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1516,
      UniqueID: 4716,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 929,
      MaxSquareFeet: 929,
      MarketRent: 3750,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1516",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1517,
      UniqueID: 4715,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 926,
      MaxSquareFeet: 926,
      MarketRent: 4039.2,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1517",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1518,
      UniqueID: 4714,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 80,
        },
      ],
      UnitType: "fr-af-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 945,
      MaxSquareFeet: 945,
      MarketRent: 1907,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - affordable",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1518",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1601,
      UniqueID: 4723,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 80,
        },
      ],
      UnitType: "fr-af-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 945,
      MaxSquareFeet: 945,
      MarketRent: 1907,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - affordable",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1601",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1602,
      UniqueID: 4724,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 926,
      MaxSquareFeet: 926,
      MarketRent: 3750,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1602",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1603,
      UniqueID: 4725,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 929,
      MaxSquareFeet: 929,
      MarketRent: 4150,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1603",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1604,
      UniqueID: 4726,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 79,
        },
      ],
      UnitType: "fr-af-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 659,
      MaxSquareFeet: 659,
      MarketRent: 1405,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - affordable",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1604",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1605,
      UniqueID: 4727,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 86,
        },
      ],
      UnitType: "fr-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 601,
      MaxSquareFeet: 601,
      MarketRent: 2650,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1605",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1606,
      UniqueID: 4728,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 647,
      MaxSquareFeet: 647,
      MarketRent: 2850,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1606",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1607,
      UniqueID: 4729,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 443,
      MaxSquareFeet: 443,
      MarketRent: 2315.4,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1607",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1608,
      UniqueID: 4721,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 870,
      MaxSquareFeet: 870,
      MarketRent: 3845.4,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1608",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1609,
      UniqueID: 4722,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 86,
        },
      ],
      UnitType: "fr-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 511,
      MaxSquareFeet: 511,
      MarketRent: 2805,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1609",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1610,
      UniqueID: 4731,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 86,
        },
      ],
      UnitType: "fr-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 511,
      MaxSquareFeet: 511,
      MarketRent: 2805,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1610",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1611,
      UniqueID: 4730,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 870,
      MaxSquareFeet: 870,
      MarketRent: 3825,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1611",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1612,
      UniqueID: 4738,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 79,
        },
      ],
      UnitType: "fr-af-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 443,
      MaxSquareFeet: 443,
      MarketRent: 1405,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - affordable",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1612",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1613,
      UniqueID: 4737,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 647,
      MaxSquareFeet: 647,
      MarketRent: 2850,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1613",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1614,
      UniqueID: 4736,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 86,
        },
      ],
      UnitType: "fr-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 601,
      MaxSquareFeet: 601,
      MarketRent: 2650,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1614",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1615,
      UniqueID: 4735,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 79,
        },
      ],
      UnitType: "fr-af-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 659,
      MaxSquareFeet: 659,
      MarketRent: 1405,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - affordable",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1615",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1616,
      UniqueID: 4734,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 929,
      MaxSquareFeet: 929,
      MarketRent: 4150,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1616",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1617,
      UniqueID: 4733,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 926,
      MaxSquareFeet: 926,
      MarketRent: 3750,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1617",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1618,
      UniqueID: 4732,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 80,
        },
      ],
      UnitType: "fr-af-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 945,
      MaxSquareFeet: 945,
      MarketRent: 1907,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - affordable",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1618",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1701,
      UniqueID: 4741,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 80,
        },
      ],
      UnitType: "fr-af-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 945,
      MaxSquareFeet: 945,
      MarketRent: 1907,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - affordable",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1701",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1702,
      UniqueID: 4742,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 926,
      MaxSquareFeet: 926,
      MarketRent: 3795,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1702",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1703,
      UniqueID: 4743,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 929,
      MaxSquareFeet: 929,
      MarketRent: 4150,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1703",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1704,
      UniqueID: 4744,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 659,
      MaxSquareFeet: 659,
      MarketRent: 2695,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1704",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1705,
      UniqueID: 4745,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 82,
        },
      ],
      UnitType: "fr-af-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 601,
      MaxSquareFeet: 601,
      MarketRent: 1035,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - affordable",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1705",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1706,
      UniqueID: 4746,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 647,
      MaxSquareFeet: 647,
      MarketRent: 2895,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1706",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1707,
      UniqueID: 4747,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 79,
        },
      ],
      UnitType: "fr-af-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 443,
      MaxSquareFeet: 443,
      MarketRent: 1405,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - affordable",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1707",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1708,
      UniqueID: 4739,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 870,
      MaxSquareFeet: 870,
      MarketRent: 3927,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1708",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1709,
      UniqueID: 4740,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 86,
        },
      ],
      UnitType: "fr-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 511,
      MaxSquareFeet: 511,
      MarketRent: 2850,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1709",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1710,
      UniqueID: 4749,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 86,
        },
      ],
      UnitType: "fr-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 511,
      MaxSquareFeet: 511,
      MarketRent: 2850,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1710",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1711,
      UniqueID: 4748,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 870,
      MaxSquareFeet: 870,
      MarketRent: 3896.4,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1711",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1712,
      UniqueID: 4756,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 443,
      MaxSquareFeet: 443,
      MarketRent: 2350,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1712",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1713,
      UniqueID: 4755,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 647,
      MaxSquareFeet: 647,
      MarketRent: 2895,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1713",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1714,
      UniqueID: 4754,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 82,
        },
      ],
      UnitType: "fr-af-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 601,
      MaxSquareFeet: 601,
      MarketRent: 2650,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - affordable",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1714",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1715,
      UniqueID: 4753,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 79,
        },
      ],
      UnitType: "fr-af-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 659,
      MaxSquareFeet: 659,
      MarketRent: 1405,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - affordable",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1715",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1716,
      UniqueID: 4752,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 929,
      MaxSquareFeet: 929,
      MarketRent: 4150,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1716",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1717,
      UniqueID: 4751,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 926,
      MaxSquareFeet: 926,
      MarketRent: 3795,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1717",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1718,
      UniqueID: 4750,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 80,
        },
      ],
      UnitType: "fr-af-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 945,
      MaxSquareFeet: 945,
      MarketRent: 1907,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - affordable",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1718",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1801,
      UniqueID: 4759,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 945,
      MaxSquareFeet: 945,
      MarketRent: 4195,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1801",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1802,
      UniqueID: 4760,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 80,
        },
      ],
      UnitType: "fr-af-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 926,
      MaxSquareFeet: 926,
      MarketRent: 1907,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - affordable",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1802",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1803,
      UniqueID: 4761,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 929,
      MaxSquareFeet: 929,
      MarketRent: 4195,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1803",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1804,
      UniqueID: 4762,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 79,
        },
      ],
      UnitType: "fr-af-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 659,
      MaxSquareFeet: 659,
      MarketRent: 1405,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - affordable",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1804",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1805,
      UniqueID: 4763,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 86,
        },
      ],
      UnitType: "fr-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 601,
      MaxSquareFeet: 601,
      MarketRent: 2650,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1805",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1806,
      UniqueID: 4764,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 647,
      MaxSquareFeet: 647,
      MarketRent: 2850,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1806",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1807,
      UniqueID: 4765,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 443,
      MaxSquareFeet: 443,
      MarketRent: 2350,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1807",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1808,
      UniqueID: 4757,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 870,
      MaxSquareFeet: 870,
      MarketRent: 3927,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1808",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1809,
      UniqueID: 4758,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 86,
        },
      ],
      UnitType: "fr-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 511,
      MaxSquareFeet: 511,
      MarketRent: 2850,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1809",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1810,
      UniqueID: 4767,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 86,
        },
      ],
      UnitType: "fr-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 511,
      MaxSquareFeet: 511,
      MarketRent: 2850,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1810",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1811,
      UniqueID: 4766,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 870,
      MaxSquareFeet: 870,
      MarketRent: 3896.4,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1811",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1812,
      UniqueID: 4774,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 79,
        },
      ],
      UnitType: "fr-af-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 443,
      MaxSquareFeet: 443,
      MarketRent: 1405,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - affordable",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1812",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1813,
      UniqueID: 4773,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 647,
      MaxSquareFeet: 647,
      MarketRent: 2850,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1813",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1814,
      UniqueID: 4772,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 86,
        },
      ],
      UnitType: "fr-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 601,
      MaxSquareFeet: 601,
      MarketRent: 2650,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1814",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1815,
      UniqueID: 4771,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 79,
        },
      ],
      UnitType: "fr-af-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 659,
      MaxSquareFeet: 659,
      MarketRent: 1405,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - affordable",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1815",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1816,
      UniqueID: 4770,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 929,
      MaxSquareFeet: 929,
      MarketRent: 4195,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1816",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1817,
      UniqueID: 4769,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 80,
        },
      ],
      UnitType: "fr-af-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 926,
      MaxSquareFeet: 926,
      MarketRent: 1907,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - affordable",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1817",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1818,
      UniqueID: 4768,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 945,
      MaxSquareFeet: 945,
      MarketRent: 4195,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1818",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1901,
      UniqueID: 4777,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 80,
        },
      ],
      UnitType: "fr-af-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 945,
      MaxSquareFeet: 945,
      MarketRent: 1907,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - affordable",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1901",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1902,
      UniqueID: 4778,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 926,
      MaxSquareFeet: 926,
      MarketRent: 3795,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1902",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1903,
      UniqueID: 4779,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 929,
      MaxSquareFeet: 929,
      MarketRent: 4195,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1903",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1904,
      UniqueID: 4780,
    },
    PersonID: {
      value: "t0002481",
      Type: "Applicant",
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 659,
      MaxSquareFeet: 659,
      MarketRent: 2695,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Rented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1904",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1905,
      UniqueID: 4781,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 82,
        },
      ],
      UnitType: "fr-af-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 601,
      MaxSquareFeet: 601,
      MarketRent: 2650,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - affordable",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1905",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1906,
      UniqueID: 4782,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 647,
      MaxSquareFeet: 647,
      MarketRent: 2895,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1906",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1907,
      UniqueID: 4783,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 79,
        },
      ],
      UnitType: "fr-af-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 443,
      MaxSquareFeet: 443,
      MarketRent: 1405,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - affordable",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1907",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1908,
      UniqueID: 4775,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 870,
      MaxSquareFeet: 870,
      MarketRent: 3927,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1908",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1909,
      UniqueID: 4776,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 86,
        },
      ],
      UnitType: "fr-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 511,
      MaxSquareFeet: 511,
      MarketRent: 2795,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1909",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1910,
      UniqueID: 4785,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 86,
        },
      ],
      UnitType: "fr-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 511,
      MaxSquareFeet: 511,
      MarketRent: 2795,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1910",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1911,
      UniqueID: 4784,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 870,
      MaxSquareFeet: 870,
      MarketRent: 3896.4,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1911",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1912,
      UniqueID: 4792,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 443,
      MaxSquareFeet: 443,
      MarketRent: 2350,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1912",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1913,
      UniqueID: 4791,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 647,
      MaxSquareFeet: 647,
      MarketRent: 2895,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1913",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1914,
      UniqueID: 4790,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 86,
        },
      ],
      UnitType: "fr-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 601,
      MaxSquareFeet: 601,
      MarketRent: 2650,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1914",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1915,
      UniqueID: 4789,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 79,
        },
      ],
      UnitType: "fr-af-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 659,
      MaxSquareFeet: 659,
      MarketRent: 1405,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - affordable",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1915",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1916,
      UniqueID: 4788,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 929,
      MaxSquareFeet: 929,
      MarketRent: 4195,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1916",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1917,
      UniqueID: 4787,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 80,
        },
      ],
      UnitType: "fr-af-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 926,
      MaxSquareFeet: 926,
      MarketRent: 1907,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - affordable",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1917",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 1918,
      UniqueID: 4786,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 945,
      MaxSquareFeet: 945,
      MarketRent: 4222.8,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 1918",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2001,
      UniqueID: 4795,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 945,
      MaxSquareFeet: 945,
      MarketRent: 4195,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2001",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2002,
      UniqueID: 4796,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 80,
        },
      ],
      UnitType: "fr-af-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 926,
      MaxSquareFeet: 926,
      MarketRent: 1907,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - affordable",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2002",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2003,
      UniqueID: 4797,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 929,
      MaxSquareFeet: 929,
      MarketRent: 4250,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2003",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2004,
      UniqueID: 4798,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 79,
        },
      ],
      UnitType: "fr-af-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 659,
      MaxSquareFeet: 659,
      MarketRent: 1405,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - affordable",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2004",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2005,
      UniqueID: 4799,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 86,
        },
      ],
      UnitType: "fr-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 601,
      MaxSquareFeet: 601,
      MarketRent: 2695,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2005",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2006,
      UniqueID: 4800,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 647,
      MaxSquareFeet: 647,
      MarketRent: 2895,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2006",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2007,
      UniqueID: 4801,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 443,
      MaxSquareFeet: 443,
      MarketRent: 2350,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2007",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2008,
      UniqueID: 4793,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 870,
      MaxSquareFeet: 870,
      MarketRent: 4049.4,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2008",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2009,
      UniqueID: 4794,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 86,
        },
      ],
      UnitType: "fr-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 511,
      MaxSquareFeet: 511,
      MarketRent: 2795,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2009",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2010,
      UniqueID: 4803,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 86,
        },
      ],
      UnitType: "fr-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 511,
      MaxSquareFeet: 511,
      MarketRent: 2795,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2010",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2011,
      UniqueID: 4802,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 870,
      MaxSquareFeet: 870,
      MarketRent: 4029,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2011",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2012,
      UniqueID: 4810,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 443,
      MaxSquareFeet: 443,
      MarketRent: 2395,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2012",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2013,
      UniqueID: 4809,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 647,
      MaxSquareFeet: 647,
      MarketRent: 2895,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2013",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2014,
      UniqueID: 4808,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 86,
        },
      ],
      UnitType: "fr-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 601,
      MaxSquareFeet: 601,
      MarketRent: 2695,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2014",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2015,
      UniqueID: 4807,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 79,
        },
      ],
      UnitType: "fr-af-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 659,
      MaxSquareFeet: 659,
      MarketRent: 1405,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - affordable",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2015",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2016,
      UniqueID: 4806,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 929,
      MaxSquareFeet: 929,
      MarketRent: 4250,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2016",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2017,
      UniqueID: 4805,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 80,
        },
      ],
      UnitType: "fr-af-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 926,
      MaxSquareFeet: 926,
      MarketRent: 1907,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - affordable",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2017",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2018,
      UniqueID: 4804,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 945,
      MaxSquareFeet: 945,
      MarketRent: 4195,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2018",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2101,
      UniqueID: 4813,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 80,
        },
      ],
      UnitType: "fr-af-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 945,
      MaxSquareFeet: 945,
      MarketRent: 1907,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - affordable",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2101",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2102,
      UniqueID: 4814,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 926,
      MaxSquareFeet: 926,
      MarketRent: 3850,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2102",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2103,
      UniqueID: 4815,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 929,
      MaxSquareFeet: 929,
      MarketRent: 4250,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2103",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2104,
      UniqueID: 4816,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 659,
      MaxSquareFeet: 659,
      MarketRent: 2695,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2104",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2105,
      UniqueID: 4817,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 86,
        },
      ],
      UnitType: "fr-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 601,
      MaxSquareFeet: 601,
      MarketRent: 2695,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2105",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2106,
      UniqueID: 4818,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 647,
      MaxSquareFeet: 647,
      MarketRent: 2995,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2106",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2107,
      UniqueID: 4819,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 443,
      MaxSquareFeet: 443,
      MarketRent: 2395,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2107",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2108,
      UniqueID: 4811,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 870,
      MaxSquareFeet: 870,
      MarketRent: 4049.4,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2108",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2109,
      UniqueID: 4812,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 86,
        },
      ],
      UnitType: "fr-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 511,
      MaxSquareFeet: 511,
      MarketRent: 2795,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2109",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2110,
      UniqueID: 4821,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 86,
        },
      ],
      UnitType: "fr-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 511,
      MaxSquareFeet: 511,
      MarketRent: 2795,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2110",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2111,
      UniqueID: 4820,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 870,
      MaxSquareFeet: 870,
      MarketRent: 4029,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2111",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2112,
      UniqueID: 4828,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 443,
      MaxSquareFeet: 443,
      MarketRent: 2395,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2112",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2113,
      UniqueID: 4827,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 647,
      MaxSquareFeet: 647,
      MarketRent: 2950,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2113",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2114,
      UniqueID: 4826,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 86,
        },
      ],
      UnitType: "fr-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 601,
      MaxSquareFeet: 601,
      MarketRent: 2695,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2114",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2115,
      UniqueID: 4825,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 79,
        },
      ],
      UnitType: "fr-af-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 659,
      MaxSquareFeet: 659,
      MarketRent: 1405,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - affordable",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2115",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2116,
      UniqueID: 4824,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 929,
      MaxSquareFeet: 929,
      MarketRent: 4250,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2116",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2117,
      UniqueID: 4823,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 926,
      MaxSquareFeet: 926,
      MarketRent: 3850,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2117",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2118,
      UniqueID: 4822,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 80,
        },
      ],
      UnitType: "fr-af-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 945,
      MaxSquareFeet: 945,
      MarketRent: 1907,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - affordable",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2118",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2201,
      UniqueID: 4831,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 945,
      MaxSquareFeet: 945,
      MarketRent: 4250,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2201",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2202,
      UniqueID: 4832,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 80,
        },
      ],
      UnitType: "fr-af-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 926,
      MaxSquareFeet: 926,
      MarketRent: 1907,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - affordable",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2202",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2203,
      UniqueID: 4833,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 929,
      MaxSquareFeet: 929,
      MarketRent: 4295,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2203",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2204,
      UniqueID: 4834,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 659,
      MaxSquareFeet: 659,
      MarketRent: 2750,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2204",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2205,
      UniqueID: 4835,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 86,
        },
      ],
      UnitType: "fr-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 601,
      MaxSquareFeet: 601,
      MarketRent: 2750,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2205",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2206,
      UniqueID: 4836,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 647,
      MaxSquareFeet: 647,
      MarketRent: 2895,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2206",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2207,
      UniqueID: 4837,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 443,
      MaxSquareFeet: 443,
      MarketRent: 2450,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2207",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2208,
      UniqueID: 4829,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 870,
      MaxSquareFeet: 870,
      MarketRent: 4049.4,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2208",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2209,
      UniqueID: 4830,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 86,
        },
      ],
      UnitType: "fr-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 511,
      MaxSquareFeet: 511,
      MarketRent: 2795,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2209",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2210,
      UniqueID: 4839,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 86,
        },
      ],
      UnitType: "fr-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 511,
      MaxSquareFeet: 511,
      MarketRent: 2795,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2210",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2211,
      UniqueID: 4838,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 870,
      MaxSquareFeet: 870,
      MarketRent: 4029,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2211",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2212,
      UniqueID: 4846,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 443,
      MaxSquareFeet: 443,
      MarketRent: 2450,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2212",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2213,
      UniqueID: 4845,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 647,
      MaxSquareFeet: 647,
      MarketRent: 2895,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2213",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2214,
      UniqueID: 4844,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 86,
        },
      ],
      UnitType: "fr-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 601,
      MaxSquareFeet: 601,
      MarketRent: 2750,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2214",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2215,
      UniqueID: 4843,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 659,
      MaxSquareFeet: 659,
      MarketRent: 2795,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2215",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2216,
      UniqueID: 4842,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 929,
      MaxSquareFeet: 929,
      MarketRent: 4295,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2216",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2217,
      UniqueID: 4841,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 926,
      MaxSquareFeet: 926,
      MarketRent: 3850,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2217",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2218,
      UniqueID: 4840,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 945,
      MaxSquareFeet: 945,
      MarketRent: 4250,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2218",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2301,
      UniqueID: 4849,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 945,
      MaxSquareFeet: 945,
      MarketRent: 4250,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2301",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2302,
      UniqueID: 4850,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 926,
      MaxSquareFeet: 926,
      MarketRent: 3895,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2302",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2303,
      UniqueID: 4851,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 929,
      MaxSquareFeet: 929,
      MarketRent: 4295,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2303",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2304,
      UniqueID: 4852,
    },
    PersonID: {
      value: "t0002363",
      Type: "Applicant",
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 659,
      MaxSquareFeet: 659,
      MarketRent: 2795,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Rented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2304",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2305,
      UniqueID: 4853,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 86,
        },
      ],
      UnitType: "fr-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 601,
      MaxSquareFeet: 601,
      MarketRent: 2750,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2305",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2306,
      UniqueID: 4854,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 647,
      MaxSquareFeet: 647,
      MarketRent: 2950,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2306",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2307,
      UniqueID: 4855,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 443,
      MaxSquareFeet: 443,
      MarketRent: 2450,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2307",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2308,
      UniqueID: 4847,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 870,
      MaxSquareFeet: 870,
      MarketRent: 4131,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2308",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2309,
      UniqueID: 4848,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 86,
        },
      ],
      UnitType: "fr-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 511,
      MaxSquareFeet: 511,
      MarketRent: 2850,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2309",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2310,
      UniqueID: 4857,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 86,
        },
      ],
      UnitType: "fr-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 511,
      MaxSquareFeet: 511,
      MarketRent: 2850,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2310",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2311,
      UniqueID: 4856,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 870,
      MaxSquareFeet: 870,
      MarketRent: 4100.4,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2311",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2312,
      UniqueID: 4864,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 443,
      MaxSquareFeet: 443,
      MarketRent: 2450,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2312",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2313,
      UniqueID: 4863,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 647,
      MaxSquareFeet: 647,
      MarketRent: 2950,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2313",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2314,
      UniqueID: 4862,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 86,
        },
      ],
      UnitType: "fr-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 601,
      MaxSquareFeet: 601,
      MarketRent: 2750,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2314",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2315,
      UniqueID: 4861,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 79,
        },
      ],
      UnitType: "fr-af-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 659,
      MaxSquareFeet: 659,
      MarketRent: 1405,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - affordable",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2315",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2316,
      UniqueID: 4860,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 929,
      MaxSquareFeet: 929,
      MarketRent: 4295,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2316",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2317,
      UniqueID: 4859,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 926,
      MaxSquareFeet: 926,
      MarketRent: 3895,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2317",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2318,
      UniqueID: 4858,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 945,
      MaxSquareFeet: 945,
      MarketRent: 4250,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2318",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2401,
      UniqueID: 4867,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 1074,
      MaxSquareFeet: 1074,
      MarketRent: 4495,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2401",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2402,
      UniqueID: 4868,
    },
    PersonID: {
      value: "t0002520",
      Type: "Applicant",
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 985,
      MaxSquareFeet: 985,
      MarketRent: 4395,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Rented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2402",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2403,
      UniqueID: 4869,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 942,
      MaxSquareFeet: 942,
      MarketRent: 4295,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2403",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2404,
      UniqueID: 4870,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 86,
        },
      ],
      UnitType: "fr-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 575,
      MaxSquareFeet: 575,
      MarketRent: 2750,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2404",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2405,
      UniqueID: 4871,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 86,
        },
      ],
      UnitType: "fr-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 572,
      MaxSquareFeet: 572,
      MarketRent: 2750,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2405",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2406,
      UniqueID: 4872,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 961,
      MaxSquareFeet: 961,
      MarketRent: 4488,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2406",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2407,
      UniqueID: 4865,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 1015,
      MaxSquareFeet: 1015,
      MarketRent: 4600.2,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2407",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2408,
      UniqueID: 4866,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 86,
        },
      ],
      UnitType: "fr-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 588,
      MaxSquareFeet: 588,
      MarketRent: 2895,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2408",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2409,
      UniqueID: 4874,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 86,
        },
      ],
      UnitType: "fr-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 588,
      MaxSquareFeet: 588,
      MarketRent: 2895,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2409",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2410,
      UniqueID: 4873,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 1015,
      MaxSquareFeet: 1015,
      MarketRent: 4600.2,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2410",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2411,
      UniqueID: 4880,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 961,
      MaxSquareFeet: 961,
      MarketRent: 4488,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2411",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2412,
      UniqueID: 4879,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 86,
        },
      ],
      UnitType: "fr-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 572,
      MaxSquareFeet: 572,
      MarketRent: 2750,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2412",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2413,
      UniqueID: 4878,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 86,
        },
      ],
      UnitType: "fr-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 575,
      MaxSquareFeet: 575,
      MarketRent: 2750,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2413",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2414,
      UniqueID: 4877,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 942,
      MaxSquareFeet: 942,
      MarketRent: 4295,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2414",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2415,
      UniqueID: 4876,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 985,
      MaxSquareFeet: 985,
      MarketRent: 4395,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2415",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2416,
      UniqueID: 4875,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 1074,
      MaxSquareFeet: 1074,
      MarketRent: 4495,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2416",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2501,
      UniqueID: 4883,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 1074,
      MaxSquareFeet: 1074,
      MarketRent: 4495,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2501",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2502,
      UniqueID: 4884,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 985,
      MaxSquareFeet: 985,
      MarketRent: 4395,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2502",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2503,
      UniqueID: 4885,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 942,
      MaxSquareFeet: 942,
      MarketRent: 4295,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2503",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2504,
      UniqueID: 4886,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 86,
        },
      ],
      UnitType: "fr-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 575,
      MaxSquareFeet: 575,
      MarketRent: 2850,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2504",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2505,
      UniqueID: 4887,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 86,
        },
      ],
      UnitType: "fr-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 572,
      MaxSquareFeet: 572,
      MarketRent: 2850,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2505",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2506,
      UniqueID: 4888,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 961,
      MaxSquareFeet: 961,
      MarketRent: 4437,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2506",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2507,
      UniqueID: 4881,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 1015,
      MaxSquareFeet: 1015,
      MarketRent: 4651.2,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2507",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2508,
      UniqueID: 4882,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 86,
        },
      ],
      UnitType: "fr-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 588,
      MaxSquareFeet: 588,
      MarketRent: 2895,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2508",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2509,
      UniqueID: 4890,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 86,
        },
      ],
      UnitType: "fr-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 588,
      MaxSquareFeet: 588,
      MarketRent: 2895,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2509",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2510,
      UniqueID: 4889,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 1015,
      MaxSquareFeet: 1015,
      MarketRent: 4651.2,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2510",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2511,
      UniqueID: 4896,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 961,
      MaxSquareFeet: 961,
      MarketRent: 4437,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2511",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2512,
      UniqueID: 4895,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 86,
        },
      ],
      UnitType: "fr-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 572,
      MaxSquareFeet: 572,
      MarketRent: 2795,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2512",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2513,
      UniqueID: 4894,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 86,
        },
      ],
      UnitType: "fr-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 575,
      MaxSquareFeet: 575,
      MarketRent: 2795,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2513",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2514,
      UniqueID: 4893,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 942,
      MaxSquareFeet: 942,
      MarketRent: 4295,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2514",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2515,
      UniqueID: 4892,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 985,
      MaxSquareFeet: 985,
      MarketRent: 4395,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2515",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2516,
      UniqueID: 4891,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 1074,
      MaxSquareFeet: 1074,
      MarketRent: 4495,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2516",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2601,
      UniqueID: 4899,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 1074,
      MaxSquareFeet: 1074,
      MarketRent: 4495,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2601",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2602,
      UniqueID: 4900,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 985,
      MaxSquareFeet: 985,
      MarketRent: 4495,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2602",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2603,
      UniqueID: 4901,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 942,
      MaxSquareFeet: 942,
      MarketRent: 4350,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2603",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2604,
      UniqueID: 4902,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 86,
        },
      ],
      UnitType: "fr-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 575,
      MaxSquareFeet: 575,
      MarketRent: 2850,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2604",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2605,
      UniqueID: 4903,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 86,
        },
      ],
      UnitType: "fr-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 572,
      MaxSquareFeet: 572,
      MarketRent: 2850,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2605",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2606,
      UniqueID: 4904,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 961,
      MaxSquareFeet: 961,
      MarketRent: 4569.6,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2606",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2607,
      UniqueID: 4897,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 1015,
      MaxSquareFeet: 1015,
      MarketRent: 4722.6,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2607",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2608,
      UniqueID: 4898,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 86,
        },
      ],
      UnitType: "fr-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 588,
      MaxSquareFeet: 588,
      MarketRent: 2895,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2608",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2609,
      UniqueID: 4906,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 86,
        },
      ],
      UnitType: "fr-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 588,
      MaxSquareFeet: 588,
      MarketRent: 2895,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2609",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2610,
      UniqueID: 4905,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 1015,
      MaxSquareFeet: 1015,
      MarketRent: 4722.6,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2610",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2611,
      UniqueID: 4912,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 961,
      MaxSquareFeet: 961,
      MarketRent: 4569.6,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2611",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2612,
      UniqueID: 4911,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 86,
        },
      ],
      UnitType: "fr-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 572,
      MaxSquareFeet: 572,
      MarketRent: 2795,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2612",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2613,
      UniqueID: 4910,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 86,
        },
      ],
      UnitType: "fr-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 575,
      MaxSquareFeet: 575,
      MarketRent: 2795,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2613",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2614,
      UniqueID: 4909,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 942,
      MaxSquareFeet: 942,
      MarketRent: 4350,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2614",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2615,
      UniqueID: 4908,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 985,
      MaxSquareFeet: 985,
      MarketRent: 4495,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2615",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2616,
      UniqueID: 4907,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 1074,
      MaxSquareFeet: 1074,
      MarketRent: 4495,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2616",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2701,
      UniqueID: 4915,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 1074,
      MaxSquareFeet: 1074,
      MarketRent: 4550,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2701",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2702,
      UniqueID: 4916,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 985,
      MaxSquareFeet: 985,
      MarketRent: 4495,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2702",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2703,
      UniqueID: 4917,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 942,
      MaxSquareFeet: 942,
      MarketRent: 4350,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2703",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2704,
      UniqueID: 4918,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 86,
        },
      ],
      UnitType: "fr-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 575,
      MaxSquareFeet: 575,
      MarketRent: 2850,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2704",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2705,
      UniqueID: 4919,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 86,
        },
      ],
      UnitType: "fr-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 572,
      MaxSquareFeet: 572,
      MarketRent: 2850,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2705",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2706,
      UniqueID: 4920,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 961,
      MaxSquareFeet: 961,
      MarketRent: 4518.6,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2706",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2707,
      UniqueID: 4913,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 1015,
      MaxSquareFeet: 1015,
      MarketRent: 4722.6,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2707",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2708,
      UniqueID: 4914,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 86,
        },
      ],
      UnitType: "fr-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 588,
      MaxSquareFeet: 588,
      MarketRent: 2895,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2708",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2709,
      UniqueID: 4922,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 86,
        },
      ],
      UnitType: "fr-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 588,
      MaxSquareFeet: 588,
      MarketRent: 2895,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2709",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2710,
      UniqueID: 4921,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 1015,
      MaxSquareFeet: 1015,
      MarketRent: 4722.6,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2710",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2711,
      UniqueID: 4928,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 961,
      MaxSquareFeet: 961,
      MarketRent: 4518.6,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2711",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2712,
      UniqueID: 4927,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 86,
        },
      ],
      UnitType: "fr-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 572,
      MaxSquareFeet: 572,
      MarketRent: 2795,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2712",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2713,
      UniqueID: 4926,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 86,
        },
      ],
      UnitType: "fr-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 575,
      MaxSquareFeet: 575,
      MarketRent: 2850,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2713",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2714,
      UniqueID: 4925,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 942,
      MaxSquareFeet: 942,
      MarketRent: 4350,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2714",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2715,
      UniqueID: 4924,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 985,
      MaxSquareFeet: 985,
      MarketRent: 4495,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2715",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2716,
      UniqueID: 4923,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 1074,
      MaxSquareFeet: 1074,
      MarketRent: 4550,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2716",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2801,
      UniqueID: 4931,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 1074,
      MaxSquareFeet: 1074,
      MarketRent: 4550,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2801",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2802,
      UniqueID: 4932,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 985,
      MaxSquareFeet: 985,
      MarketRent: 4495,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2802",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2803,
      UniqueID: 4933,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 942,
      MaxSquareFeet: 942,
      MarketRent: 4350,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2803",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2804,
      UniqueID: 4934,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 86,
        },
      ],
      UnitType: "fr-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 575,
      MaxSquareFeet: 575,
      MarketRent: 2795,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2804",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2805,
      UniqueID: 4935,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 86,
        },
      ],
      UnitType: "fr-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 572,
      MaxSquareFeet: 572,
      MarketRent: 2795,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2805",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2806,
      UniqueID: 4936,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 961,
      MaxSquareFeet: 961,
      MarketRent: 4467.6,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2806",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2807,
      UniqueID: 4929,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 1015,
      MaxSquareFeet: 1015,
      MarketRent: 4722.6,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2807",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2808,
      UniqueID: 4930,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 86,
        },
      ],
      UnitType: "fr-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 588,
      MaxSquareFeet: 588,
      MarketRent: 2895,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2808",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2809,
      UniqueID: 4938,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 86,
        },
      ],
      UnitType: "fr-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 588,
      MaxSquareFeet: 588,
      MarketRent: 2895,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2809",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2810,
      UniqueID: 4937,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 1015,
      MaxSquareFeet: 1015,
      MarketRent: 4722.6,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2810",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2811,
      UniqueID: 4944,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 961,
      MaxSquareFeet: 961,
      MarketRent: 4467.6,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2811",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2812,
      UniqueID: 4943,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 86,
        },
      ],
      UnitType: "fr-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 572,
      MaxSquareFeet: 572,
      MarketRent: 2795,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2812",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2813,
      UniqueID: 4942,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 86,
        },
      ],
      UnitType: "fr-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 575,
      MaxSquareFeet: 575,
      MarketRent: 2895,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2813",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2814,
      UniqueID: 4941,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 942,
      MaxSquareFeet: 942,
      MarketRent: 4350,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2814",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2815,
      UniqueID: 4940,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 985,
      MaxSquareFeet: 985,
      MarketRent: 4495,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2815",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2816,
      UniqueID: 4939,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 1074,
      MaxSquareFeet: 1074,
      MarketRent: 4550,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2816",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2901,
      UniqueID: 4947,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 1074,
      MaxSquareFeet: 1074,
      MarketRent: 4550,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2901",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2902,
      UniqueID: 4948,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 985,
      MaxSquareFeet: 985,
      MarketRent: 4550,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2902",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2903,
      UniqueID: 4949,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 942,
      MaxSquareFeet: 942,
      MarketRent: 4395,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2903",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2904,
      UniqueID: 4950,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 86,
        },
      ],
      UnitType: "fr-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 575,
      MaxSquareFeet: 575,
      MarketRent: 2795,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2904",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2905,
      UniqueID: 4951,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 86,
        },
      ],
      UnitType: "fr-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 572,
      MaxSquareFeet: 572,
      MarketRent: 2795,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2905",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2906,
      UniqueID: 4952,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 961,
      MaxSquareFeet: 961,
      MarketRent: 4539,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2906",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2907,
      UniqueID: 4945,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 1015,
      MaxSquareFeet: 1015,
      MarketRent: 4804.2,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2907",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2908,
      UniqueID: 4946,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 86,
        },
      ],
      UnitType: "fr-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 588,
      MaxSquareFeet: 588,
      MarketRent: 2895,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2908",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2909,
      UniqueID: 4954,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 86,
        },
      ],
      UnitType: "fr-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 588,
      MaxSquareFeet: 588,
      MarketRent: 2895,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2909",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2910,
      UniqueID: 4953,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 1015,
      MaxSquareFeet: 1015,
      MarketRent: 4804.2,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2910",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2911,
      UniqueID: 4960,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 961,
      MaxSquareFeet: 961,
      MarketRent: 4539,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2911",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2912,
      UniqueID: 4959,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 86,
        },
      ],
      UnitType: "fr-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 572,
      MaxSquareFeet: 572,
      MarketRent: 2795,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2912",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2913,
      UniqueID: 4958,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 86,
        },
      ],
      UnitType: "fr-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 575,
      MaxSquareFeet: 575,
      MarketRent: 2795,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2913",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2914,
      UniqueID: 4957,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 942,
      MaxSquareFeet: 942,
      MarketRent: 4395,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2914",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2915,
      UniqueID: 4956,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 985,
      MaxSquareFeet: 985,
      MarketRent: 4550,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2915",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 2916,
      UniqueID: 4955,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 1074,
      MaxSquareFeet: 1074,
      MarketRent: 4550,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 2916",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 3001,
      UniqueID: 4963,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 1066,
      MaxSquareFeet: 1066,
      MarketRent: 5095,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 3001",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 3002,
      UniqueID: 4964,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 993,
      MaxSquareFeet: 993,
      MarketRent: 5095,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 3002",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 3003,
      UniqueID: 4965,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 939,
      MaxSquareFeet: 939,
      MarketRent: 4795,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 3003",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 3004,
      UniqueID: 4966,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 893,
      MaxSquareFeet: 893,
      MarketRent: 4692,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 3004",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 3005,
      UniqueID: 4967,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 1088,
      MaxSquareFeet: 1088,
      MarketRent: 5295,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 3005",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 3006,
      UniqueID: 4961,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 1027,
      MaxSquareFeet: 1027,
      MarketRent: 5095,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 3006",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 3007,
      UniqueID: 4962,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 86,
        },
      ],
      UnitType: "fr-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 592,
      MaxSquareFeet: 592,
      MarketRent: 2895,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 3007",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 3008,
      UniqueID: 4969,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 86,
        },
      ],
      UnitType: "fr-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 592,
      MaxSquareFeet: 592,
      MarketRent: 2895,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 3008",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 3009,
      UniqueID: 4968,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 1027,
      MaxSquareFeet: 1027,
      MarketRent: 5095,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 3009",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 301,
      UniqueID: 4477,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 779,
      MaxSquareFeet: 779,
      MarketRent: 3295,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 301",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 3010,
      UniqueID: 4974,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 1088,
      MaxSquareFeet: 1088,
      MarketRent: 5295,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 3010",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 3011,
      UniqueID: 4973,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 892,
      MaxSquareFeet: 892,
      MarketRent: 5295,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 3011",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 3012,
      UniqueID: 4972,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 939,
      MaxSquareFeet: 939,
      MarketRent: 4795,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 3012",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 3013,
      UniqueID: 4971,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 993,
      MaxSquareFeet: 993,
      MarketRent: 5095,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 3013",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 3014,
      UniqueID: 4970,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 1066,
      MaxSquareFeet: 1066,
      MarketRent: 5095,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 3014",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 302,
      UniqueID: 4478,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 660,
      MaxSquareFeet: 660,
      MarketRent: 3000,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 302",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 303,
      UniqueID: 4479,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 79,
        },
      ],
      UnitType: "fr-af-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 591,
      MaxSquareFeet: 591,
      MarketRent: 1405,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - affordable",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 303",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 304,
      UniqueID: 4480,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 80,
        },
      ],
      UnitType: "fr-af-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 894,
      MaxSquareFeet: 894,
      MarketRent: 1907,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - affordable",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 304",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 305,
      UniqueID: 4484,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 80,
        },
      ],
      UnitType: "fr-af-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 860,
      MaxSquareFeet: 860,
      MarketRent: 1907,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - affordable",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 305",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 306,
      UniqueID: 4483,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 79,
        },
      ],
      UnitType: "fr-af-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 592,
      MaxSquareFeet: 592,
      MarketRent: 1405,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - affordable",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 306",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 307,
      UniqueID: 4482,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 664,
      MaxSquareFeet: 664,
      MarketRent: 3200,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 307",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 308,
      UniqueID: 4481,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 775,
      MaxSquareFeet: 775,
      MarketRent: 3295,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 308",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 3101,
      UniqueID: 4976,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 85,
        },
      ],
      UnitType: "fr-3b",
      UnitBedrooms: 3,
      UnitBathrooms: 2,
      MinSquareFeet: 1595,
      MaxSquareFeet: 1595,
      MarketRent: 8415,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "3 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 3101",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 3102,
      UniqueID: 4977,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 85,
        },
      ],
      UnitType: "fr-3b",
      UnitBedrooms: 3,
      UnitBathrooms: 2,
      MinSquareFeet: 1340,
      MaxSquareFeet: 1340,
      MarketRent: 7078.8,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "3 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 3102",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 3103,
      UniqueID: 4978,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 85,
        },
      ],
      UnitType: "fr-3b",
      UnitBedrooms: 3,
      UnitBathrooms: 2,
      MinSquareFeet: 1420,
      MaxSquareFeet: 1420,
      MarketRent: 7486.8,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "3 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 3103",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 3104,
      UniqueID: 4975,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 85,
        },
      ],
      UnitType: "fr-3b",
      UnitBedrooms: 3,
      UnitBathrooms: 2,
      MinSquareFeet: 1615,
      MaxSquareFeet: 1615,
      MarketRent: 8466,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "3 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 3104",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 3105,
      UniqueID: 4979,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 85,
        },
      ],
      UnitType: "fr-3b",
      UnitBedrooms: 3,
      UnitBathrooms: 2,
      MinSquareFeet: 1615,
      MaxSquareFeet: 1615,
      MarketRent: 8466,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "3 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 3105",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 3106,
      UniqueID: 4982,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 85,
        },
      ],
      UnitType: "fr-3b",
      UnitBedrooms: 3,
      UnitBathrooms: 2,
      MinSquareFeet: 1420,
      MaxSquareFeet: 1420,
      MarketRent: 7486.8,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "3 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 3106",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 3107,
      UniqueID: 4981,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 85,
        },
      ],
      UnitType: "fr-3b",
      UnitBedrooms: 3,
      UnitBathrooms: 2,
      MinSquareFeet: 1340,
      MaxSquareFeet: 1340,
      MarketRent: 7078.8,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "3 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 3107",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 3108,
      UniqueID: 4980,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 85,
        },
      ],
      UnitType: "fr-3b",
      UnitBedrooms: 3,
      UnitBathrooms: 2,
      MinSquareFeet: 1595,
      MaxSquareFeet: 1595,
      MarketRent: 8394.6,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "3 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 3108",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 401,
      UniqueID: 4487,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 647,
      MaxSquareFeet: 647,
      MarketRent: 2695,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 401",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 402,
      UniqueID: 4488,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 80,
        },
      ],
      UnitType: "fr-af-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 783,
      MaxSquareFeet: 783,
      MarketRent: 1907,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - affordable",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 402",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 403,
      UniqueID: 4494,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 79,
        },
      ],
      UnitType: "fr-af-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 494,
      MaxSquareFeet: 494,
      MarketRent: 2175,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - affordable",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 403",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 404,
      UniqueID: 4489,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 717,
      MaxSquareFeet: 717,
      MarketRent: 2995,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 404",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 405,
      UniqueID: 4490,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 86,
        },
      ],
      UnitType: "fr-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 619,
      MaxSquareFeet: 619,
      MarketRent: 2695,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 405",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 406,
      UniqueID: 4491,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 86,
        },
      ],
      UnitType: "fr-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 619,
      MaxSquareFeet: 619,
      MarketRent: 2695,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 406",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 407,
      UniqueID: 4492,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 721,
      MaxSquareFeet: 721,
      MarketRent: 3095,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 407",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 408,
      UniqueID: 4493,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 502,
      MaxSquareFeet: 502,
      MarketRent: 2295,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 408",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 409,
      UniqueID: 4485,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 801,
      MaxSquareFeet: 801,
      MarketRent: 3250,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 409",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 410,
      UniqueID: 4486,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 633,
      MaxSquareFeet: 633,
      MarketRent: 2795,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 410",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 411,
      UniqueID: 4496,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 633,
      MaxSquareFeet: 633,
      MarketRent: 2795,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 411",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 412,
      UniqueID: 4495,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 801,
      MaxSquareFeet: 801,
      MarketRent: 3250,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 412",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 413,
      UniqueID: 4503,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 79,
        },
      ],
      UnitType: "fr-af-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 502,
      MaxSquareFeet: 502,
      MarketRent: 1405,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - affordable",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 413",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 414,
      UniqueID: 4502,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 721,
      MaxSquareFeet: 721,
      MarketRent: 2995,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 414",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 415,
      UniqueID: 4501,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 86,
        },
      ],
      UnitType: "fr-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 619,
      MaxSquareFeet: 619,
      MarketRent: 2695,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 415",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 416,
      UniqueID: 4500,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 86,
        },
      ],
      UnitType: "fr-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 619,
      MaxSquareFeet: 619,
      MarketRent: 2695,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 416",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 417,
      UniqueID: 4499,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 717,
      MaxSquareFeet: 717,
      MarketRent: 2995,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 417",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 418,
      UniqueID: 4504,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 79,
        },
      ],
      UnitType: "fr-af-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 494,
      MaxSquareFeet: 494,
      MarketRent: 2195,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - affordable",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 418",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 419,
      UniqueID: 4498,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 80,
        },
      ],
      UnitType: "fr-af-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 783,
      MaxSquareFeet: 783,
      MarketRent: 1907,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - affordable",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 419",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 420,
      UniqueID: 4497,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 647,
      MaxSquareFeet: 647,
      MarketRent: 2695,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 420",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 501,
      UniqueID: 4507,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 647,
      MaxSquareFeet: 647,
      MarketRent: 2695,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 501",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 502,
      UniqueID: 4508,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 783,
      MaxSquareFeet: 783,
      MarketRent: 3195,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 502",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 503,
      UniqueID: 4514,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 494,
      MaxSquareFeet: 494,
      MarketRent: 2175,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 503",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 504,
      UniqueID: 4509,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 717,
      MaxSquareFeet: 717,
      MarketRent: 2995,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 504",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 505,
      UniqueID: 4510,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 86,
        },
      ],
      UnitType: "fr-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 619,
      MaxSquareFeet: 619,
      MarketRent: 2595,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 505",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 506,
      UniqueID: 4511,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 82,
        },
      ],
      UnitType: "fr-af-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 619,
      MaxSquareFeet: 619,
      MarketRent: 1035,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - affordable",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 506",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 507,
      UniqueID: 4512,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 721,
      MaxSquareFeet: 721,
      MarketRent: 3095,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 507",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 508,
      UniqueID: 4513,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 79,
        },
      ],
      UnitType: "fr-af-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 502,
      MaxSquareFeet: 502,
      MarketRent: 1405,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - affordable",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 508",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 509,
      UniqueID: 4505,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 80,
        },
      ],
      UnitType: "fr-af-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 801,
      MaxSquareFeet: 801,
      MarketRent: 1907,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - affordable",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 509",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 510,
      UniqueID: 4506,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 633,
      MaxSquareFeet: 633,
      MarketRent: 2795,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 510",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 511,
      UniqueID: 4516,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 633,
      MaxSquareFeet: 633,
      MarketRent: 2795,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 511",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 512,
      UniqueID: 4515,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 80,
        },
      ],
      UnitType: "fr-af-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 801,
      MaxSquareFeet: 801,
      MarketRent: 1907,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - affordable",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 512",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 513,
      UniqueID: 4523,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 79,
        },
      ],
      UnitType: "fr-af-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 502,
      MaxSquareFeet: 502,
      MarketRent: 1405,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - affordable",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 513",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 514,
      UniqueID: 4522,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 721,
      MaxSquareFeet: 721,
      MarketRent: 2995,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 514",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 515,
      UniqueID: 4521,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 82,
        },
      ],
      UnitType: "fr-af-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 619,
      MaxSquareFeet: 619,
      MarketRent: 1035,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - affordable",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 515",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 516,
      UniqueID: 4520,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 86,
        },
      ],
      UnitType: "fr-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 619,
      MaxSquareFeet: 619,
      MarketRent: 2595,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 516",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 517,
      UniqueID: 4519,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 717,
      MaxSquareFeet: 717,
      MarketRent: 2995,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 517",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 518,
      UniqueID: 4524,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 494,
      MaxSquareFeet: 494,
      MarketRent: 2195,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 518",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 519,
      UniqueID: 4518,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 783,
      MaxSquareFeet: 783,
      MarketRent: 3172.2,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 519",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 520,
      UniqueID: 4517,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 647,
      MaxSquareFeet: 647,
      MarketRent: 2695,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 520",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 601,
      UniqueID: 4527,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 647,
      MaxSquareFeet: 647,
      MarketRent: 2750,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 601",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 602,
      UniqueID: 4528,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 80,
        },
      ],
      UnitType: "fr-af-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 783,
      MaxSquareFeet: 783,
      MarketRent: 1907,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - affordable",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 602",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 603,
      UniqueID: 4534,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 494,
      MaxSquareFeet: 494,
      MarketRent: 1405,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 603",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 604,
      UniqueID: 4529,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 717,
      MaxSquareFeet: 717,
      MarketRent: 2995,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 604",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 605,
      UniqueID: 4530,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 82,
        },
      ],
      UnitType: "fr-af-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 619,
      MaxSquareFeet: 619,
      MarketRent: 1035,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - affordable",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 605",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 606,
      UniqueID: 4531,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 86,
        },
      ],
      UnitType: "fr-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 619,
      MaxSquareFeet: 619,
      MarketRent: 2595,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 606",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 607,
      UniqueID: 4532,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 721,
      MaxSquareFeet: 721,
      MarketRent: 3095,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 607",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 608,
      UniqueID: 4533,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 79,
        },
      ],
      UnitType: "fr-af-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 502,
      MaxSquareFeet: 502,
      MarketRent: 1405,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - affordable",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 608",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 609,
      UniqueID: 4525,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 801,
      MaxSquareFeet: 801,
      MarketRent: 3250,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 609",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 610,
      UniqueID: 4526,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 633,
      MaxSquareFeet: 633,
      MarketRent: 2795,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 610",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 611,
      UniqueID: 4536,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 633,
      MaxSquareFeet: 633,
      MarketRent: 2795,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 611",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 612,
      UniqueID: 4535,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 801,
      MaxSquareFeet: 801,
      MarketRent: 3250,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 612",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 613,
      UniqueID: 4543,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 502,
      MaxSquareFeet: 502,
      MarketRent: 2295,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 613",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 614,
      UniqueID: 4542,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 721,
      MaxSquareFeet: 721,
      MarketRent: 2995,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 614",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 615,
      UniqueID: 4541,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 86,
        },
      ],
      UnitType: "fr-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 619,
      MaxSquareFeet: 619,
      MarketRent: 2595,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 615",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 616,
      UniqueID: 4540,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 82,
        },
      ],
      UnitType: "fr-af-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 619,
      MaxSquareFeet: 619,
      MarketRent: 1035,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - affordable",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 616",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 617,
      UniqueID: 4539,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 717,
      MaxSquareFeet: 717,
      MarketRent: 2995,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 617",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 618,
      UniqueID: 4544,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 79,
        },
      ],
      UnitType: "fr-af-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 494,
      MaxSquareFeet: 494,
      MarketRent: 2195,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - affordable",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 618",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 619,
      UniqueID: 4538,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 80,
        },
      ],
      UnitType: "fr-af-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 783,
      MaxSquareFeet: 783,
      MarketRent: 1907,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - affordable",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 619",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 620,
      UniqueID: 4537,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 647,
      MaxSquareFeet: 647,
      MarketRent: 2750,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 620",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 701,
      UniqueID: 4547,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 647,
      MaxSquareFeet: 647,
      MarketRent: 2750,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 701",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 702,
      UniqueID: 4548,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 783,
      MaxSquareFeet: 783,
      MarketRent: 3195,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 702",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 703,
      UniqueID: 4554,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 494,
      MaxSquareFeet: 494,
      MarketRent: 2225,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 703",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 704,
      UniqueID: 4549,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 717,
      MaxSquareFeet: 717,
      MarketRent: 3050,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 704",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 705,
      UniqueID: 4550,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 86,
        },
      ],
      UnitType: "fr-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 619,
      MaxSquareFeet: 619,
      MarketRent: 2595,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 705",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 706,
      UniqueID: 4551,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 82,
        },
      ],
      UnitType: "fr-af-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 619,
      MaxSquareFeet: 619,
      MarketRent: 1035,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - affordable",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 706",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 707,
      UniqueID: 4552,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 721,
      MaxSquareFeet: 721,
      MarketRent: 3150,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 707",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 708,
      UniqueID: 4553,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 79,
        },
      ],
      UnitType: "fr-af-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 502,
      MaxSquareFeet: 502,
      MarketRent: 1405,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - affordable",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 708",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 709,
      UniqueID: 4545,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 80,
        },
      ],
      UnitType: "fr-af-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 801,
      MaxSquareFeet: 801,
      MarketRent: 1907,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - affordable",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 709",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 710,
      UniqueID: 4546,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 633,
      MaxSquareFeet: 633,
      MarketRent: 2795,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 710",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 711,
      UniqueID: 4556,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 633,
      MaxSquareFeet: 633,
      MarketRent: 2795,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 711",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 712,
      UniqueID: 4555,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 80,
        },
      ],
      UnitType: "fr-af-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 801,
      MaxSquareFeet: 801,
      MarketRent: 1907,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - affordable",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 712",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 713,
      UniqueID: 4563,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 79,
        },
      ],
      UnitType: "fr-af-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 502,
      MaxSquareFeet: 502,
      MarketRent: 1405,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - affordable",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 713",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 714,
      UniqueID: 4562,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 721,
      MaxSquareFeet: 721,
      MarketRent: 3050,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 714",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 715,
      UniqueID: 4561,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 82,
        },
      ],
      UnitType: "fr-af-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 619,
      MaxSquareFeet: 619,
      MarketRent: 1035,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - affordable",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 715",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 716,
      UniqueID: 4560,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 86,
        },
      ],
      UnitType: "fr-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 619,
      MaxSquareFeet: 619,
      MarketRent: 2595,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 716",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 717,
      UniqueID: 4559,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 717,
      MaxSquareFeet: 717,
      MarketRent: 3050,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 717",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 718,
      UniqueID: 4564,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 494,
      MaxSquareFeet: 494,
      MarketRent: 2250,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 718",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 719,
      UniqueID: 4558,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 783,
      MaxSquareFeet: 783,
      MarketRent: 3250,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 719",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 720,
      UniqueID: 4557,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 647,
      MaxSquareFeet: 647,
      MarketRent: 2750,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 720",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 801,
      UniqueID: 4567,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 647,
      MaxSquareFeet: 647,
      MarketRent: 2750,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 801",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 802,
      UniqueID: 4568,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 80,
        },
      ],
      UnitType: "fr-af-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 783,
      MaxSquareFeet: 783,
      MarketRent: 1907,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - affordable",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 802",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 803,
      UniqueID: 4574,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 79,
        },
      ],
      UnitType: "fr-af-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 494,
      MaxSquareFeet: 494,
      MarketRent: 2225,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - affordable",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 803",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 804,
      UniqueID: 4569,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 717,
      MaxSquareFeet: 717,
      MarketRent: 3050,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 804",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 805,
      UniqueID: 4570,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 82,
        },
      ],
      UnitType: "fr-af-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 619,
      MaxSquareFeet: 619,
      MarketRent: 1035,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - affordable",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 805",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 806,
      UniqueID: 4571,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 86,
        },
      ],
      UnitType: "fr-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 619,
      MaxSquareFeet: 619,
      MarketRent: 2595,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 806",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 807,
      UniqueID: 4572,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 721,
      MaxSquareFeet: 721,
      MarketRent: 3150,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 807",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 808,
      UniqueID: 4573,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 502,
      MaxSquareFeet: 502,
      MarketRent: 2350,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 808",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 809,
      UniqueID: 4565,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 801,
      MaxSquareFeet: 801,
      MarketRent: 3315,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 809",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 810,
      UniqueID: 4566,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 633,
      MaxSquareFeet: 633,
      MarketRent: 2795,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 810",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 811,
      UniqueID: 4576,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 633,
      MaxSquareFeet: 633,
      MarketRent: 2795,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 811",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 812,
      UniqueID: 4575,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 801,
      MaxSquareFeet: 801,
      MarketRent: 3315,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 812",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 813,
      UniqueID: 4583,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 502,
      MaxSquareFeet: 502,
      MarketRent: 2350,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 813",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 814,
      UniqueID: 4582,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 721,
      MaxSquareFeet: 721,
      MarketRent: 3050,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 814",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 815,
      UniqueID: 4581,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 86,
        },
      ],
      UnitType: "fr-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 619,
      MaxSquareFeet: 619,
      MarketRent: 2595,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 815",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 816,
      UniqueID: 4580,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 82,
        },
      ],
      UnitType: "fr-af-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 619,
      MaxSquareFeet: 619,
      MarketRent: 1035,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - affordable",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 816",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 817,
      UniqueID: 4579,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 717,
      MaxSquareFeet: 717,
      MarketRent: 3050,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 817",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 818,
      UniqueID: 4584,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 79,
        },
      ],
      UnitType: "fr-af-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 494,
      MaxSquareFeet: 494,
      MarketRent: 2250,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - affordable",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 818",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 819,
      UniqueID: 4578,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 80,
        },
      ],
      UnitType: "fr-af-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 783,
      MaxSquareFeet: 783,
      MarketRent: 1907,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - affordable",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 819",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 820,
      UniqueID: 4577,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 647,
      MaxSquareFeet: 647,
      MarketRent: 2750,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 820",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 901,
      UniqueID: 4587,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 647,
      MaxSquareFeet: 647,
      MarketRent: 2750,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 901",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 902,
      UniqueID: 4588,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 783,
      MaxSquareFeet: 783,
      MarketRent: 3250,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 902",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 903,
      UniqueID: 4594,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 494,
      MaxSquareFeet: 494,
      MarketRent: 2225,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 903",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 904,
      UniqueID: 4589,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 717,
      MaxSquareFeet: 717,
      MarketRent: 3050,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 904",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 905,
      UniqueID: 4590,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 86,
        },
      ],
      UnitType: "fr-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 619,
      MaxSquareFeet: 619,
      MarketRent: 2595,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 905",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 906,
      UniqueID: 4591,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 82,
        },
      ],
      UnitType: "fr-af-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 619,
      MaxSquareFeet: 619,
      MarketRent: 1035,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - affordable",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 906",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 907,
      UniqueID: 4592,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 721,
      MaxSquareFeet: 721,
      MarketRent: 3150,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 907",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 908,
      UniqueID: 4593,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 79,
        },
      ],
      UnitType: "fr-af-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 502,
      MaxSquareFeet: 502,
      MarketRent: 1405,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - affordable",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 908",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 909,
      UniqueID: 4585,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 80,
        },
      ],
      UnitType: "fr-af-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 801,
      MaxSquareFeet: 801,
      MarketRent: 1907,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - affordable",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 909",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 910,
      UniqueID: 4586,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 633,
      MaxSquareFeet: 633,
      MarketRent: 2795,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-south",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 910",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 911,
      UniqueID: 4596,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 633,
      MaxSquareFeet: 633,
      MarketRent: 2795,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 911",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 912,
      UniqueID: 4595,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 80,
        },
      ],
      UnitType: "fr-af-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 801,
      MaxSquareFeet: 801,
      MarketRent: 1907,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - affordable",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 912",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 913,
      UniqueID: 4603,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 79,
        },
      ],
      UnitType: "fr-af-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 502,
      MaxSquareFeet: 502,
      MarketRent: 1405,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - affordable",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 913",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 914,
      UniqueID: 4602,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 721,
      MaxSquareFeet: 721,
      MarketRent: 3050,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 914",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 915,
      UniqueID: 4601,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 82,
        },
      ],
      UnitType: "fr-af-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 619,
      MaxSquareFeet: 619,
      MarketRent: 1035,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - affordable",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 915",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 916,
      UniqueID: 4600,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 86,
        },
      ],
      UnitType: "fr-st",
      UnitBedrooms: 0,
      UnitBathrooms: 1,
      MinSquareFeet: 619,
      MaxSquareFeet: 619,
      MarketRent: 2595,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "Studio - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 916",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 917,
      UniqueID: 4599,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 717,
      MaxSquareFeet: 717,
      MarketRent: 3050,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 917",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 918,
      UniqueID: 4604,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 494,
      MaxSquareFeet: 494,
      MarketRent: 2250,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 918",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 919,
      UniqueID: 4598,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 84,
        },
      ],
      UnitType: "fr-2b",
      UnitBedrooms: 2,
      UnitBathrooms: 0,
      MinSquareFeet: 783,
      MaxSquareFeet: 783,
      MarketRent: 3295,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "2 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 919",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
  {
    UnitID: {
      value: 920,
      UniqueID: 4597,
    },
    Unit: {
      Identification: [
        {
          IDType: "RentalType",
          IDValue: "Residential",
        },
        {
          IDType: "UnitTypeUniqueID",
          IDValue: 83,
        },
      ],
      UnitType: "fr-1b",
      UnitBedrooms: 1,
      UnitBathrooms: 1,
      MinSquareFeet: 647,
      MaxSquareFeet: 647,
      MarketRent: 2750,
      UnitEconomicStatus: "residential",
      UnitEconomicStatusDescription: "Vacant Unrented Ready",
      FloorplanName: "1 bedroom - market",
      BuildingName: "fry-north",
      Address: {
        AddressType: "property",
        AddressLine1: "707 Terry Ave",
        AddressLine2: "Unit 920",
        City: "Seattle",
        State: "WA",
        PostalCode: 98104,
        Country: "United States",
      },
    },
  },
];
